import { useEffect } from 'react'
import Cookie from 'js-cookie'
import { COOKIE_THEME_KEY, THEME_DOMAIN } from 'hooks/useTheme'

const useThemeCookie = () => {
  const themeValue = 'dark'

  useEffect(() => {
    const getThemeCookie = Cookie.get(COOKIE_THEME_KEY)

    if (!getThemeCookie && getThemeCookie !== themeValue) {
      Cookie.set(COOKIE_THEME_KEY, themeValue, { domain: THEME_DOMAIN })
    }
  }, [themeValue])
}

export default useThemeCookie
