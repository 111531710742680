import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="64" height="51" viewBox="0 0 64 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.93262 14.0696V5.41138H64.5452L42.9098 40.0443H32.8133L49.0399 14.0696H7.93262Z" fill="white" />
      <path d="M56.6126 48.3417V57H0L21.6354 22.3671H31.7319L15.5054 48.3417H56.6126Z" fill="white" />
    </svg>
  );
};

export default Icon;
