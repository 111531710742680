import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | ZenDEX',
  defaultTitle: 'ZenDEX',
  description: 'ZenDEX - Swap, Liquidity & Farms!',
  // TODO: ZenDEX Update
  twitter: {
    cardType: 'summary_large_image',
    handle: '@ZenDEX_zkp',
    site: '@ZenDEX_zkp',
  },
  // TODO: ZenDEX Update
  openGraph: {
    title: 'Community Driven Concentrated Liquidity AMM on Manta. Join ZenDEX today!',
    description: 'Community Driven Concentrated Liquidity AMM on Manta. Join ZenDEX today!',
    images: [{ url: 'https://app.ZenDEX.com/logo.png' }],
  },
}
