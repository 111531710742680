import React from "react";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="269" height="57" viewBox="0 0 269 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.93262 14.0696V5.41138H64.5452L42.9098 40.0443H32.8133L49.0399 14.0696H7.93262Z" fill="white" />
      <path d="M56.6126 48.3417V57H0L21.6354 22.3671H31.7319L15.5054 48.3417H56.6126Z" fill="white" />
      <path
        d="M77.8848 51.9494L94.8743 20.3033H77.8848V14.467H104.24L87.2745 46.0898H104.24V51.9494H77.8848Z"
        fill="white"
      />
      <path
        d="M132.317 20.2568H115.885V29.8599H130.062V35.7195H115.885V46.0898H132.317V51.9494H110.005V14.467H132.317V20.2568Z"
        fill="white"
      />
      <path
        d="M162.669 14.374H168.572V52.6004H168.293L144.332 27.5579V51.9959H138.451V13.8159H138.753L162.669 38.8119V14.374Z"
        fill="white"
      />
      <path
        d="M186.444 14.467C198.344 14.4437 205.758 21.6519 205.758 33.2546C205.758 44.7644 198.344 51.9493 186.444 51.9493H175.776V14.467H186.444ZM186.491 46.0898C194.672 46.0898 199.808 41.1371 199.808 33.2314C199.808 25.2792 194.648 20.3032 186.421 20.3032H181.633V46.0898H186.491Z"
        fill="white"
      />
      <path
        d="M233.461 20.2568H217.03V29.8599H231.207V35.7195H217.03V46.0898H233.461V51.9494H211.149V14.467H233.461V20.2568Z"
        fill="white"
      />
      <path
        d="M236.529 51.9494L248.568 32.4874L237.343 14.467H244.129L252.007 27.3021L259.84 14.467H266.626L255.401 32.4641L267.44 51.9494H260.723L251.984 37.8121L243.269 51.9494H236.529Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
