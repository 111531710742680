import { ChainId } from '@pancakeswap/sdk'
import memoize from 'lodash/memoize'
import {
  arbitrum,
  arbitrumGoerli,
  bsc as bsc_,
  bscTestnet,
  Chain,
  goerli,
  lineaTestnet as lineaTestnet_,
  mainnet,
  polygonZkEvm as polygonZkEvm_,
  polygonZkEvmTestnet as polygonZkEvmTestnet_,
  zkSync,
  zkSyncTestnet,
} from 'wagmi/chains'

export const CHAIN_QUERY_NAME = {
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
  [ChainId.ARBITRUM_ONE]: 'arb',
  [ChainId.ARBITRUM_GOERLI]: 'arbGoerli',
  [ChainId.POLYGON_ZKEVM]: 'polygonZkEVM',
  [ChainId.POLYGON_ZKEVM_TESTNET]: 'polygonZkEVMTestnet',
  [ChainId.ZKSYNC]: 'zkSync',
  [ChainId.ZKSYNC_TESTNET]: 'zkSyncTestnet',
  [ChainId.LINEA_TESTNET]: 'lineaTestnet',
  [ChainId.SCROLL_SEPOLIA]: 'scrollSepoliaTestnet',
  [ChainId.MANTA_TESTNET]: 'mantaTestnet',
  [ChainId.MANTA_MAINNET]: 'manta',
} as const satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName.toLowerCase()]: chainId as unknown as ChainId,
    ...acc,
  }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

const bsc = {
  ...bsc_,
  rpcUrls: {
    ...bsc_.rpcUrls,
    public: {
      ...bsc_.rpcUrls.public,
      http: ['https://bsc-dataseed.binance.org/'],
    },
    default: {
      ...bsc_.rpcUrls.default,
      http: ['https://bsc-dataseed.binance.org/'],
    },
  },
} satisfies Chain

const polygonZkEvm = {
  ...polygonZkEvm_,
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 57746,
    },
  },
} as const satisfies Chain

const polygonZkEvmTestnet = {
  ...polygonZkEvmTestnet_,
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 525686,
    },
  },
} as const satisfies Chain

const mantaTestnet = {
  id: 3441005,
  name: 'Manta Testnet',
  network: 'manta-testnet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://manta-testnet.calderachain.xyz/http'],
    },
    public: {
      http: ['https://manta-testnet.calderachain.xyz/http'],
    },
  },
  blockExplorers: {
    default: {
      name: 'manta-testnet Explorer',
      url: 'https://manta-testnet.calderaexplorer.xyz',
    },
  },
  testnet: true,
  contracts: {
    multicall3: {
      address: '0xaFC1DbcebBF3AcaBB9a9b8CE44dCCe033D2b2E9a',
      blockCreated: 308,
    },
  },
} as const satisfies Chain

const mantaMainnet = {
  id: 169,
  name: 'Manta Mainnet',
  network: 'manta-mainnet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://pacific-rpc.manta.network/http'],
    },
    public: {
      http: ['https://pacific-rpc.manta.network/http'],
    },
  },
  blockExplorers: {
    default: {
      name: 'manta Explorer',
      url: 'https://pacific-explorer.manta.network',
    },
  },
  testnet: false,
  contracts: {
    multicall3: {
      address: '0xaFC1DbcebBF3AcaBB9a9b8CE44dCCe033D2b2E9a',
      blockCreated: 40689,
    },
  },
} as const satisfies Chain

const lineaTestnet = {
  ...lineaTestnet_,
  blockExplorers: {
    etherscan: {
      name: 'LineaScan',
      url: 'https://goerli.lineascan.build',
    },
    default: {
      name: 'LineaScan',
      url: 'https://goerli.lineascan.build',
    },
  },
} as const satisfies Chain

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [ChainId.MANTA_MAINNET]

export const CHAINS = [mantaMainnet]
