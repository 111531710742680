import { ChainId, WETH9 } from '@pancakeswap/sdk'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import PoolABI from 'config/abi/IPancakeV3Pool.json'
import contracts from 'config/constants/contracts'
import { publicClient } from 'utils/wagmi'
import { formatUnits } from 'viem'
import { useContractRead } from 'wagmi'
import { sqrtRatioX96ToPrice } from '@pancakeswap/v3-sdk'
import { fetchTokenUSDValues } from '@pancakeswap/farms'
import { CAKE, USDC } from '@pancakeswap/tokens'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import { useCallback, useEffect, useState } from 'react'
import { CurrencyAmount } from '@pancakeswap/swap-sdk-core'

const PRICING_POOL_ADDRESS = '0xe0c74a117b19475e44b00ff5223a0f813353f698'
// for migration to bignumber.js to avoid breaking changes
export const useCakePrice = () => {
  const { data } = useContractRead({
    abi: PoolABI,
    address: PRICING_POOL_ADDRESS,
    functionName: 'slot0',
    chainId: ChainId.MANTA_MAINNET,
    watch: true,
    select: (d) => new BigNumber(formatUnits(d[0], 0) || '0'),
  })
  const [price, setPrice] = useState<string>('0')

  const updatePrice = useCallback(async () => {
    if (!data) return
    const priceInToken = sqrtRatioX96ToPrice(
      BigInt(data.toNumber()),
      CAKE[ChainId.MANTA_MAINNET],
      USDC[ChainId.MANTA_MAINNET],
    )
    const usdPrice = priceInToken.quote(CurrencyAmount.fromRawAmount(CAKE[ChainId.MANTA_MAINNET], 10 ** 18))
    setPrice(usdPrice.toSignificant(4))
  }, [data])

  useEffect(() => {
    updatePrice()
  }, [updatePrice])
  return price ? new BigNumber(price) : BIG_ZERO
}

export const getCakePriceFromOracle = async () => {
  const data = await publicClient({ chainId: ChainId.BSC }).readContract({
    abi: chainlinkOracleABI,
    address: contracts.chainlinkOracleCAKE[ChainId.BSC],
    functionName: 'latestAnswer',
  })

  return formatUnits(data, 8)
}
