import { mantaMainnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

// TODO: MANTA
export const farmsV3 = defineFarmV3Configs([
  /*{
    pid: 1,
    lpAddress: '0xe0c74a117b19475e44b00ff5223a0f813353f698',
    token0: mantaMainnetTokens.usdc,
    token1: mantaMainnetTokens.weth,
    feeAmount: FeeAmount.HIGH,
  },
   */
])
